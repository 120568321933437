<template>
  <div class="receita-container">
    
    <!-- -->
      <section id="secao-receita" class="container-fluid">

        <div class="row px-lg-3 py-3" style="background:#F18800;">
            
          <div class="col-sm-10">
              <h2 class="bold" style="color:#FFF;"><a href="https://tarimbanacozinha.com.br/ticket"><span> 
     <svg style="color:#FFF;" xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-arrow-left-circle" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
</svg>
                
                </span></a> {{nome}} </h2>
              <star-rating active-color="#400B41"  v-model="media" :read-only="true" :show-rating="false" :star-size="25" />
          </div>

              <div class="col-sm-2">
                <p style="display:inline-block;color:#FFF;font-weight:bold;" class="bold ">REDES SOCIAIS: </p><br>
               
                <a target="_blank" href="https://www.facebook.com/tarimbanacozinha" class="d-inline-block mr-4"> <img style="height:40px;cursor:pointer;" src="/img/facebook.png" class="img-fluid " alt="Facebook Tarimba na cozinha" /></a>
                 <a target="_blank" href="https://www.instagram.com/tarimbanacozinha/" class="d-inline-block"><img style="height:40px;cursor:pointer;" src="/img/instagram.png" class="img-fluid " alt="Instagram Tarimba na cozinha" /></a>
                
              </div>

          </div>

<br>
          <div class="row px-lg-3">

            <div class="col-sm-12 col-md-3">

              <div class="row">
                <div class="col-sm-12">
                  <img src="/img/ticket.jpg" width="250" height="auto"  class="img-fluid img-thumbnail d-block mx-auto" />
               
                <br>
                 <h5 class="txt-receita-roxo text-center"><i>Autor(a):</i></h5>
                    <h4 class="txt-receita-roxo bold text-center">{{ (receita.RE_TARIMBA == 'Sim') ? 'Equipe Tarimba':usuario.USU_NOME}}</h4>
                    <br>
                    <!--<button @click="imprimir" style="background-color:#F18800 !important;color:#FFF !important;" class="btn btn-light bold py-3 px-3">IMPRIMIR RECEITA</button> -->
                </div>

                
              </div>

            </div>

            <div class="col-sm-12 col-md-9">
              <!--   -->
  <section id="tabela-informacao" style="background:#EF953A" class="px-3 px-lg-3 py-3 box-exibicao-receita">
                <div class="row">
                   <div class="col-lg-6">

                     
                    
                      <div class="row">
                          <div class="col-4 box-icone text-center">
                          <p class="txt-receita-white bold text-center" style="font-weight:bold;">CATEGORIA</p>
                          <img :src="'/img/'+icone" class="img-fluid" style="max-width:85px;"/>
                          <h4 class="txt-receita-white bold txt-icones" style="margin-top:5px;">{{categoria}}</h4> 
                          </div>

                        <div class="col-4 box-icone text-center"> 
                          <p class="txt-receita-white bold text-center" style="font-weight:bold;">DIETA</p>
                          <img src="/img/icon_det01.png" class="img-fluid" style=""/>
                          <h4 class="txt-receita-white bold txt-icones" style="margin-top:5px;">
                          {{ (dieta != '') ? dieta:'N/A'}}</h4> 
                          </div>

                        <div class="col-4 box-icone text-center">
                          <p class="txt-receita-white bold text-center" style="font-weight:bold;">RENDIMENTO</p>
                          <img src="/img/icon_det02.png" class="img-fluid" style=""/>
                          <h4 class="txt-receita-white bold txt-icones" style="margin-top:5px;">{{rendimento}} Pessoas</h4> 
                            </div>

                        <div  class="col-4 box-icone text-center">
                          <p class="txt-receita-white bold text-center" style="font-weight:bold;">TEMPO</p>
                          <img src="/img/icon_det03.png" class="img-fluid" style=""/>
                         <h4 class="txt-receita-white bold txt-icones" style="margin-top:5px;">{{tempo}} Min</h4> 
                        </div>

                        <div  class="col-4 box-icone text-center"> 
                          <p class="txt-receita-white bold text-center" style="font-weight:bold;">DIFICULDADE</p>
                          <img src="/img/icon_det04.png" class="img-fluid" style=""/>
                          <h4 class="txt-receita-white bold txt-icones" style="margin-top:5px;">{{dificuldade}}</h4> 
                          </div>

                        <div  class="col-4 box-icone text-center">
                          <p class="txt-receita-white bold text-center" style="font-weight:bold;">CUSTO</p>
                          <img src="/img/icon_det05.png" class="img-fluid" style=""/>
                            <h4 class="txt-receita-white bold txt-icones" style="margin-top:5px;">{{custo}}</h4> 
                            </div>
                      </div>

                  </div>

                   <div class="col-lg-6">
                      <img v-if="foto != null && foto.length > 0" :src="foto"   class="img-fluid img-thumbnail d-block mx-auto" />
                      <img v-else src="/img/no-image.png"   class="img-fluid img-thumbnail d-block mx-auto" />
                       <p v-if="preparo == 'Sim'" class="txt-receita-white"><strong>* Essa receita exige pré-preparo.</strong></p>
                  </div>
                </div>
              </section>



              <!-- -->
              <!--<section id="tabela-informacao" style="background:#EF953A" class="px-3 px-lg-3 py-3 box-exibicao-receita">
                <div class="row">
                   <div class="col-md-12 col-lg-8">
                      <star-rating v-model="media" :read-only="true" :show-rating="false" :star-size="30" />
                      <h2 class="bold txt-receita-white">Categoria</h2>
                       <button @click="adicionarTeste" class="btn btn-light bold float-right d-none d-sm-block " style="color:#F18800;font-size:0.7em;margin-top:-70px;">ADICIONE EM RECEITAS PARA TESTAR</button>
                     <button @click="adicionarTeste" class="btn btn-light bold d-block  d-sm-none" style="color:#F18800;font-size:0.7em;">ADICIONE EM RECEITAS PARA TESTAR</button>

                     <button @click="adicionarCompras" class="btn btn-light bold float-right d-none d-sm-block " style="color:#F18800;font-size:0.7em;margin-top:-30px;">ADICIONE EM LISTA DE COMPRAS</button>
                     <button @click="adicionarCompras" class="btn btn-light bold d-block  d-sm-none" style="color:#F18800;font-size:0.7em;">ADICIONE EM LISTA DE COMPRAS</button>

                     <div class="clearfix"></div>
                      <div class="row px-0">
                        <div class="col-sm-12 px-0">
                            <img :src="'/img/'+icone" class="img-fluid d-inline" style="width:80px;"/> <h5 class="d-inline txt-receita-white">{{categoria}}</h5>
                            <p v-if="preparo == 'Sim'" class="txt-receita-white"><strong>* Essa receita exige pré-preparo.</strong></p>
                        </div>
                       
                      </div>
<hr>
                      <div class="row">
                        <div class="col box-icone text-center"> 
                          <p class="txt-receita-white text-center">Dieta</p>
                          <img src="/img/icon_det01.png" class="img-fluid" style=""/>
                          <h4 class="txt-receita-white bold txt-icones">
                          {{ (dieta != '') ? dieta:'N/A'}}</h4> 
                          </div>

                        <div class="col box-icone text-center">
                          <p class="txt-receita-white text-center">Rendimento</p>
                          <img src="/img/icon_det02.png" class="img-fluid" style=""/>
                          <h4 class="txt-receita-white bold txt-icones">{{rendimento}} Pessoas</h4> 
                            </div>

                        <div  class="col box-icone text-center">
                          <p class="txt-receita-white text-center">Tempo</p>
                          <img src="/img/icon_det03.png" class="img-fluid" style=""/>
                         <h4 class="txt-receita-white bold txt-icones">{{tempo}} Min</h4> 
                        </div>

                        <div  class="col box-icone text-center"> 
                          <p class="txt-receita-white text-center">Dificuldade</p>
                          <img src="/img/icon_det04.png" class="img-fluid" style=""/>
                          <h4 class="txt-receita-white bold txt-icones">{{dificuldade}}</h4> 
                          </div>

                        <div  class="col box-icone text-center">
                          <p class="txt-receita-white text-center">Custo</p>
                          <img src="/img/icon_det05.png" class="img-fluid" style=""/>
                            <h4 class="txt-receita-white bold txt-icones">{{custo}}</h4> 
                            </div>
                      </div>

                  </div>

                   <div class="col-md-12 col-lg-4">
                      <img v-if="foto != null && foto.length > 0" :src="foto"   class="img-fluid img-thumbnail mx-auto" />
                      <img v-else src="/img/no-image.png"   class="img-fluid img-thumbnail mx-auto" />
                  </div>
                </div>
              </section> -->
            
            </div>

          </div>
<br><br>
<hr class="">  




<br>

<section class="row px-lg-3">
    <div class="col-sm-6">
          <Anuncio :id="5" padrao="/img/publicidade1.jpg" class="d-none d-sm-block" :mobile="false" />
           <Anuncio :id="5" padrao="/img/publicidade1.jpg" class="d-sm-none" style="margin-bottom:10px;" :mobile="true" />
    </div>
    <div class="col-sm-6">
          <Anuncio :id="6" padrao="/img/publicidade2.jpg" class="d-none d-sm-block" :mobile="false" />
           <Anuncio :id="6" padrao="/img/publicidade2.jpg" class="d-sm-none" :mobile="true" />
    </div>
</section>

<br>
 <hr class="">  
 <br>


<div class="py-3 px-3" style="background:#400B41;">
  <div class="row">
  <div class="col-sm-1">
    <svg style="color:#FFF;" width="3em" height="3em" viewBox="0 0 16 16" class="bi bi-exclamation-circle my-3" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"/>
</svg>
  </div>
    <div class="col-sm-11">
     <h3 class="bold txt-receita-white" style="margin-top:7px;">
                
                Dica Tarimba: para o seu trabalho fluir melhor, separe todos os utensílios e ingredientes necessários antes de iniciar a preparação.
              </h3>
  </div>
  </div>
             
            </div>


 <br> <br>

        <section id="tabela-equipamento" class="row px-lg-3">
          <div class="col-sm-6 margin-mobile-receita">
           <img src="/img/icon_receitas_utensilios.png" class="img-fluid"/>
<h1 class="bold d-inline " style="margin-left:20px;">Utensílios</h1><br><br>

<h4 class="txt-receita-roxo" v-for="(item,index) in utensilios" :key="'utensilio'+index">- {{item.nome}}</h4>


          </div>


           <div class="col-sm-6">

  <img src="/img/icon_receitas_equipamentos.png" class="img-fluid"/>
<h1 class="bold d-inline " style="margin-left:20px;">Equipamentos</h1><br><br>

<h4 class="txt-receita-roxo" v-for="(item,index) in equipamentos" :key="'equipamento'+index">- {{item.nome}}</h4>
<br>
           </div>

          
<br>
           
        </section>
         <!--<Anuncio :id="5" padrao="/img/publicidade1.jpg" class="d-none d-sm-block" :mobile="false" />
           <Anuncio :id="5" padrao="/img/publicidade1.jpg" class="d-sm-none" :mobile="true" /> -->

<br><br>
<hr>
<br><br>


       <section class="row px-lg-3">
          <div class="col-sm-12">
  <img src="/img/celular.jpeg" class="img-fluid d-sm-none d-block mx-auto"/>
  <br>
 <img src="/img/icon_receitas_ingredientes.png" class="img-fluid"/>
<h2 class="bold d-inline " style="margin-left:20px;">Ingredientes</h2>
          </div>

         
       </section>

       <section class="row px-lg-3">
           <div class="col-sm-12"><br>
             



            <div class="py-3 px-3 margin-mobile-receita" style="background:#400B41;">
              <h3 class="bold txt-receita-white" style="margin-top:7px;"></h3>
            </div>
         


<!-- table -->
<div id="tabela-ingredientes" class="table-responsive">
<table class="table">
  <thead class="thead-light">
     <tr>
      <th scope="col"></th>
      <th scope="col"  colspan="2">Medidas Domésticas</th>
      <th scope="col" colspan="2">Medidas Decimais</th>
     
      <th scope="col"></th>
       <th scope="col"></th>
       
       
    </tr>
    <tr>
      <th width="20" scope="col"></th>
      <th  width="30" align="right" scope="col">Quant</th>
      <th width="170" scope="col">UNID</th>
      <th width="30" align="right" scope="col">Quant</th>
      <th width="170" scope="col">UNID</th>
      <th width="450" scope="col">Ingrediente</th>
       <th scope="col">Observações</th>
       
       
    </tr>
  </thead>
  <tbody>
    <tr v-for="(item,index) in ingredientes" :key="'ingredienteReceita'+index">
      <td><input class="selectCompras" type="checkbox" :value="item" v-model="ingredientesEscolhidos" /></td>
      <td align="right" style="background-color:#FFB95A !important;font-weight:bold;">{{item.qtd.replace('.',',').replace(',0','')}}</td>
      <td style="background-color:#FFB95A !important;font-weight:bold;">{{item.medida}}</td>
      <td  align="right" style="background-color:#FFE6C9 !important;">{{item.volume.replace('.',',').replace(',0','')}}</td>
      <td style="background-color:#FFE6C9 !important;">{{item.decimal}}</td>
      <td style="background-color:#FFB95A  !important;font-weight:bold;">{{item.nome}}</td>
      <td style="background-color:#FFE6C9  !important;">{{item.obs}}</td>
     
     
     
    </tr> 
   
  </tbody>
</table>
 
</div>
<!--<Anuncio :id="6" padrao="/img/publicidade2.jpg" class="d-none d-sm-block" :mobile="false" />
           <Anuncio :id="6" padrao="/img/publicidade2.jpg" class="d-sm-none" :mobile="true" /> -->
<br>
<!-- -->





           </div>
       </section>

<br><br>
<hr>
<br><br>
<section class="row px-lg-3 section-preparo">
          <div class="col-sm-12">
<svg width="4em" height="4em" viewBox="0 0 16 16" class="bi bi-list-ol" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5z"/>
  <path d="M1.713 11.865v-.474H2c.217 0 .363-.137.363-.317 0-.185-.158-.31-.361-.31-.223 0-.367.152-.373.31h-.59c.016-.467.373-.787.986-.787.588-.002.954.291.957.703a.595.595 0 0 1-.492.594v.033a.615.615 0 0 1 .569.631c.003.533-.502.8-1.051.8-.656 0-1-.37-1.008-.794h.582c.008.178.186.306.422.309.254 0 .424-.145.422-.35-.002-.195-.155-.348-.414-.348h-.3zm-.004-4.699h-.604v-.035c0-.408.295-.844.958-.844.583 0 .96.326.96.756 0 .389-.257.617-.476.848l-.537.572v.03h1.054V9H1.143v-.395l.957-.99c.138-.142.293-.304.293-.508 0-.18-.147-.32-.342-.32a.33.33 0 0 0-.342.338v.041zM2.564 5h-.635V2.924h-.031l-.598.42v-.567l.629-.443h.635V5z"/>
</svg>
<h2 class="bold d-inline " style="margin-left:20px;">Modo de preparo</h2><br><br>

<h4 class="txt-receita-roxo bold">Passo 1:</h4>
<pre class="txt-receita-modo">{{passo1}}</pre>
<br>
<h4 class="txt-receita-roxo bold">Passo 2:</h4>
<pre class="txt-receita-modo">{{passo2}}</pre>
<br>
<h4 class="txt-receita-roxo bold">Passo 3:</h4>
<pre class="txt-receita-modo">{{passo3}}</pre>
<br>
<h4 class="txt-receita-roxo bold">Passo 4:</h4>
<pre class="txt-receita-modo">{{passo4}}</pre>
<br>
<h4 class="txt-receita-roxo bold">Passo 5:</h4>
<pre class="txt-receita-modo">{{passo5}}</pre>
<br>
<h4 class="txt-receita-roxo bold">Passo 6:</h4>
<pre class="txt-receita-modo">{{passo6}}</pre>

<br>

<div class="py-5 px-2 px-sm-4" style="background:#400B41;">
              <h3 class="bold txt-receita-laranja" style="">
                Dicas que podem melhorar sua receita
              </h3>
              <pre class="txt-receita-white">{{sugestao.trim()}}</pre>

              <h3 class="bold txt-receita-laranja" style="">
                Sugestões de acompanhamento
              </h3>
              <pre class="txt-receita-white">{{acompanhamento.trim()}}</pre>

              <h3 class="bold txt-receita-laranja" style="">
                Pontos de atenção
              </h3>
              <pre class="txt-receita-white">{{pontos.trim()}}</pre>

              <h3 class="bold txt-receita-laranja" style="">
                Terminologia técnica
              </h3>
              <pre class="txt-receita-white">{{termos.trim()}}</pre>

            </div>





          </div>
          
       </section>

<br><br>
<hr>
<br>



<br><br>
<hr>
                  
      </section>
<!-- -->








  </div>
</template>

<style>
.txt-receita-modo{color:#000;background-color:#E9ECEF;padding:5px;border-radius:5px;}
.txt-receita-roxo{color:#400B41;}
.txt-receita-laranja{color:#F18800;}
.txt-receita-white{color:#FFF;}
.box-icone{margin-bottom:25px;}
.box-icone h4{font-size:1.2em;}
.section-preparo pre{font-size:1.1em;font-family: plume;}
.box-exibicao-receita{
  -webkit-box-shadow: 4px 3px 15px 0px rgba(0,0,0,0.93); 
box-shadow: 4px 3px 15px 0px rgba(0,0,0,0.93);
}


@media all and (max-width:768px){
  .box-exibicao-receita{margin-top:15px;}
  .margin-mobile-receita{margin-bottom:10px;margin-top:10px;}
}
</style>

<script>
import servidor from '@/servidor'
import StarRating from 'vue-star-rating'

import Anuncio from '@/components/Anuncio'

export default {
  name: 'VisualizarReceitaCampanha',
  components: {StarRating,Anuncio},
  data(){
    return {
      ingredientesEscolhidos:[],
      icone:'',
      id: null,
      categoria:'',
      custo:'',
      dificuldade:'',
      rendimento:1,
      tempo:1,
      dieta:'',
      preparo:'Não',
      passo1:'',
      passo2:'',
      passo3:'',
      passo4:'',
      passo5:'',
      passo6:'',
      sugestao:'',
      acompanhamento:'',
      termos:'',
      pontos:"",
      foto:'',
      texto:'',
      nome:'',
      utensilios:[],
      equipamentos:[],
      ingredientes:[],
      usuario:{},
      receita:{},
      nota:0,
      media:0,
      comentario:''
    }
  },
  computed:{
    /*getUsuario(){
      return this.$store.state.usuario.id;
    }*/
  },
  methods:{

   buscarReceita(){
     let dados = new FormData();
     //dados.append('usuario',this.getUsuario);
      fetch(servidor+'gerenciaReceita/getReceita/'+this.id,{method:"POST", body:dados})
          .then((response) => response.json())
          .then((data) => {
            console.log(data)
            if(data.erro != null){
                this.abrirModal(data.erro);
            }else{
              this.receita = data.receita;
               this.nome = data.receita.RE_NOME;
               this.categoria = data.receita.RE_CATEGORIA;
               this.custo = data.receita.RE_CUSTO;
               this.dificuldade = data.receita.RE_DIFICULDADE;
               this.rendimento = data.receita.RE_RENDIMENTO;
               this.tempo = data.receita.RE_TEMPO;
               this.preparo = data.receita.RE_PREPARO;
               this.dieta = data.receita.RE_DIETA;
               this.passo1 = data.receita.RE_PASSO1;
               this.passo2 = data.receita.RE_PASSO2;
               this.passo3 = data.receita.RE_PASSO3;
               this.passo4 = data.receita.RE_PASSO4;
               this.passo5 = data.receita.RE_PASSO5;
               this.passo6 = data.receita.RE_PASSO6;
               this.sugestao= data.receita.RE_SUGESTAO;
               this.termos = data.receita.RE_TERMOS;
               this.pontos = data.receita.RE_PONTOS;
               this.acompanhamento = data.receita.RE_ACOMPANHAMENTO;
               this.foto = data.receita.RE_FOTO;
               this.usuario = data.usuario;
               this.nota = parseInt(data.avaliacao);
               this.media = (data.receita.RE_NOTA != null) ? parseInt(data.receita.RE_NOTA):0;
               this.setarIcone();

               data.utensilios.forEach((utensilio) => this.utensilios.push({nome:utensilio.UR_NOME,novo:utensilio.UR_NOVO}));
               data.equipamentos.forEach((equipamento) => this.equipamentos.push({nome:equipamento.ER_NOME,novo:equipamento.ER_NOVO}));
               data.ingredientes.forEach((ingrediente) => this.ingredientes.push(
                 {nome:ingrediente.IR_NOME,novo: ingrediente.IR_NOVO,qtd:ingrediente.IR_QUANTIDADE.toString(),medida:ingrediente.IR_DOMESTICA,obs:ingrediente.IR_OBSERVACAO,
                 posicao:ingrediente.IR_POSICAO,volume:ingrediente.IR_VOLUME,decimal:ingrediente.IR_DECIMAL}
               ));
            }
        });
    },
    setarIcone(){
      switch(this.categoria){
       
         case 'Entradas':
          this.icone = 'icon_categoria1.png';
        break;
         case 'Sopas, Cremes e Caldinhos':
          this.icone = 'icon_categoria2.png';
        break;
         case 'Aperitivos, Comidinhas e Lanches':
          this.icone = 'icon_categoria3.png';
        break;
        case 'Pratos Principais':
          this.icone = 'icon_categoria4.png';
        break;
         case 'Acompanhamentos':
          this.icone = 'icon_categoria5.png';
        break;
           case 'Sobremesas':
          this.icone = 'icon_categoria6.png';
        break;
         
           case 'Bolos, Doces e Pães':
          this.icone = 'icon_categoria7.png';
        break;
         case 'Molhos e Caldos Clássicos':
          this.icone = 'icon_categoria8.png';
        break;
      }
    }
  },
  mounted(){
   
    this.$scrollTo("#secao-receita");
    if(this.$route.params.id != null){
      this.id = this.$route.params.id;
      this.buscarReceita();
      
    }
  }
}
</script>
